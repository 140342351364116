export const articles = [
  { slug: "how-to-create-a-pros-and-cons-list", topNav: true },
  { slug: "pros-and-cons-of-a-pros-and-cons-list" },
  { slug: "navigating-decision-making-tools", topNav: true },
  { slug: "pros-and-cons-of-working-from-home" },
  { slug: "pros-and-cons-of-using-social-media" },
  { slug: "pros-and-cons-of-buying-an-electric-vehicle" },
  { slug: "pros-and-cons-of-getting-a-pet" },
  { slug: "pros-and-cons-of-buying-a-car" },
  { slug: "pros-and-cons-of-starting-a-business" },
  // { slug: "pros-and-cons-of-getting-solar-panels" },
  // { slug: "pros-and-cons-of-using-a-credit-card" },
  // { slug: "pros-and-cons-of-a-vegetarian-diet" },
  // { slug: "pros-and-cons-of-a-low-carb-diet" },
  // { slug: "pros-and-cons-of-going-to-college" },
  // { slug: "pros-and-cons-of-getting-a-phd" },
  // { slug: "pros-and-cons-of-getting-a-masters-degree" },
  // { slug: "pros-and-cons-of-buying-a-home" },
];
