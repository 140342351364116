import React from "react";
import { Drawer } from "./Drawer";
import { SaveList } from "./SaveList";
import { useTranslation } from "next-i18next";

interface LoadDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export function LoadDrawer({ isOpen, onClose }: LoadDrawerProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        title={t("SaveList.SavedLists")}
      >
        <SaveList handleClose={onClose} />
      </Drawer>
    </div>
  );
}
