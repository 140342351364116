const nonEUDataProtectiveTZs = [
  "Asia/Famagusta",
  "Asia/Nicosia",
  "Atlantic/Azores",
  "Atlantic/Madeira",
  // Quebec (Law 25):
  "America/Toronto",
  "America/Halifax",
  // California (CCPA):
  "America/Los_Angeles",
];
let timezone: string | undefined;
if (typeof Intl !== "undefined") {
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
}
export function isDataProtective() {
  if (timezone) {
    // non-exact check for potential European timezones
    // Check if timezone starts with Europe,
    // or it is one of the relevant timezones that does not start with "Europe/"
    // which will opt into dataProtective because of GDPR
    return (
      timezone.includes("Europe/") || nonEUDataProtectiveTZs.includes(timezone)
    );
  }
}
