import { localSavedStatesStore } from "@/state/localSavedStatesStore";
import omit from "lodash.omit";
import { ListType } from "@/state/reducers";
import { track } from "@/utils/track";

export async function areThereUnsavedChanges(currentState: ListType) {
  // current state has not been saved, but there's nothing to save
  if (!currentState.meta.key && currentState.options.length === 0) {
    return false;
  }
  // current state has been saved, but there are unsaved changes
  if (currentState.meta.key) {
    const savedState = await localSavedStatesStore.getItem(
      currentState.meta.key,
    );

    if (!savedState) {
      // todo handle this error case better
      console.error("Saved state not found", currentState.meta.key);
      return false;
    }

    const currentStateCopy = omit(currentState, "meta");
    const savedStateCopy = omit(savedState, "meta");

    // current state equals saved state, no unsaved changes
    if (JSON.stringify(currentStateCopy) === JSON.stringify(savedStateCopy)) {
      return false;
    }
  }
  return true;
}

export async function confirmUnsavedChanges(
  state: ListType,
  message?: string,
  trackMessagePrefix?: string,
) {
  const unsavedChanges = await areThereUnsavedChanges(state);
  if (!unsavedChanges) {
    return true;
  }
  const confirmSave = window.confirm(message);
  track(
    "event",
    confirmSave
      ? `${trackMessagePrefix}${
          trackMessagePrefix ? "-and-" : ""
        }discard-unsaved-list`
      : `${trackMessagePrefix}${
          trackMessagePrefix ? "-but-" : ""
        }do-not-discard-unsaved-list`,
  );
  return confirmSave;
}
