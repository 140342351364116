/* eslint-disable jsx-a11y/anchor-is-valid */
import Image from "next/image";

import {
  XMarkIcon,
  Bars3Icon,
  QuestionMarkCircleIcon,
  SunIcon, MoonIcon
} from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useRouter } from "next/router";

import { useTranslation } from "next-i18next";
import Link from "next/link";
import OnboardingModal from "./OnboardingModal";
import { articles } from "@/state/articles";
import { track } from "@/utils/track";
import i18nextConfig from "../next-i18next.config";
import { useTheme } from 'next-themes';


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function StyledSunIcon() {
  return <SunIcon data-test="theme-change-button" className="block h-6 w-6" aria-hidden="true" />;
}

export function StyledMoonIcon() {
  return <MoonIcon data-test="theme-change-button" className="block h-6 w-6" aria-hidden="true" />;
}

function ThemeChanger() {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme, resolvedTheme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  let icon;
  switch (theme) {
    case 'light':
      icon = <StyledMoonIcon />;
      break;
    case 'dark':
      icon = <StyledSunIcon />;
      break;
    case 'system':
      icon = resolvedTheme === 'dark'
        ? <StyledSunIcon />
        : <StyledMoonIcon />;
      break;
    default:
      icon = <div className="block h-6 w-6"></div>;
      break;
  }

  const handleClick = () => {
    if (theme === 'system') {
      const newTheme = resolvedTheme === 'dark' ? 'light' : 'dark';
      setTheme(newTheme);
      track("event", `click-theme-change-${newTheme}`);
    } else {
      const newTheme = theme === 'dark' ? 'light' : 'dark';
      setTheme(newTheme);
      track("event", `click-theme-change-${newTheme}`);
    }
  };

  return (
    <button
      onClick={handleClick}
      aria-label={"Toggle theme"}
      className={`ml-6 inline-flex items-center p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:text-white transition-opacity duration-300 ${!mounted ? 'invisible opacity-0' : 'opacity-100'}`}
    >
      {mounted ? icon : <div className="block h-6 w-6"></div>}
    </button>
  );
}

function DropdownMenu(props: { open: boolean; indexHref: string }) {
  const router = useRouter();

  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale;
  const menuItems = [
    { name: "Home", route: props.indexHref },
    {
      name: "Articles",
      route: `/${currentLocale}/articles`,
    },
  ];
  const { t } = useTranslation();
  const articleItems = articles
    .filter((article) => article.topNav)
    .map((article) => {
      return {
        name: article.slug,
        route: `/${currentLocale}/${article.slug}`,
        article: true,
      };
    });

  return (
    <Menu as="div" className="relative lg:ml-5 flex-shrink-0">
      <div>
        <Menu.Button className="xsm:mt-0 mx-2 leading-4 self-center items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:hover:bg-white/20 dark:text-white">
          <span className="sr-only">Open main menu</span>
          {props.open ? (
            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
          ) : (
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 md:w-96 max-h-screen overflow-y-auto origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border-0 dark:bg-darkPanelBg dark:ring-1 dark:ring-inset dark:ring-white/10 ">
          {menuItems
            .concat(articleItems)
            .map(
              (
                item: { name: string; route: string; article?: boolean },
                index,
              ) => {
                return (
                  <Menu.Item key={item.route}>
                    {({ active }) => (
                      <Link
                        onClick={() => {
                          track("event", "menu-click", {
                            event_label: item.route,
                          });
                        }}
                        href={item.route}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          index === menuItems.length
                            ? "border-t border-gray-100 border-solid dark:border-white/10"
                            : "",
                          "block px-4 py-2 text-sm text-gray-700 dark:bg-darkPanelBg dark:hover:bg-white/20 dark:text-white dark:border-white/10",
                        )}
                      >
                        {item.article
                          ? t(`articles.${item.name}.title`)
                          : t(`navItems.${item.name}`)}
                      </Link>
                    )}
                  </Menu.Item>
                );
              },
            )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default function TopHeader() {
  const { t } = useTranslation();
  const router = useRouter();

  const [onboardingModal, setOnboardingModal] = useState(false);
  const indexHref = `/${
    router.query.locale &&
    router.query.locale !== i18nextConfig.i18n.defaultLocale
      ? router.query.locale
      : ""
  }`;
  return (
    <>
      <Disclosure as="div" className="dark:bg-darkHeaderBg">
        {() => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
              <div className="relative flex h-16 justify-between">
                <div className="relative z-10 flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <Link href={indexHref}>
                      <Image
                        src="/logos/logo.svg"
                        priority={true}
                        width={32}
                        height={32}
                        alt={t("Header.logoAlt")}
                        className="block h-8 w-auto dark:drop-shadow-glow"
                      />
                    </Link>
                    <Link href={indexHref}>
                      <h1
                        data-test="top-header-heading"
                        className={`flex-1 self-center p-6 text-3xl`}
                      >
                        {t("Header.title")}
                      </h1>
                    </Link>
                  </div>
                </div>

                <div className="relative z-10 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                </div>
                <div className="relative z-10 flex items-center">
                  <div className={`inline-flex items-center`}>
                    <ThemeChanger />
                  </div>
                  <div className={`ml-6 inline-flex items-center`}>
                    <button
                      onClick={() => setOnboardingModal(true)}
                      className="inline-flex items-center p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:text-white transition-opacity duration-300"
                      data-test={"open-onboarding-modal-button"}
                      aria-label={"Open onboarding modal"}
                    >
                      <QuestionMarkCircleIcon aria-hidden="true" className="block h-6 w-6" />
                    </button>
                  </div>

                  <DropdownMenu open={false} indexHref={indexHref} />
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      {onboardingModal && (
        <OnboardingModal handleClose={() => setOnboardingModal(false)} />
      )}
    </>
  );
}
