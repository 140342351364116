import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { track } from "@/utils/track";

/**
 * Enum for the side of the argument
 * @readonly
 * @enum {string}
 */
export enum SideEnum {
  PRO = "pro",
  CON = "con",
}

/**
 * Enum for the weight of the argument
 * @readonly
 * @enum {number}
 */
export enum WeightEnum {
  LOW = 0,
  HIGH = 1,
}

/**
 * Interface for the Reason type
 * @interface
 * @property {string} optionId - The ID of the option
 * @property {string} id - The ID of the reason
 * @property {SideEnum} side - The side of the argument
 * @property {string} label - The label of the reason
 * @property {WeightEnum} weight - The weight of the reason
 */
export interface ReasonType {
  optionId: string;
  id: string;
  side: SideEnum;
  label: string;
  weight: WeightEnum;
}

/**
 * Interface for the Option type
 * @interface
 * @property {string} id - The ID of the option
 * @property {string} label - The label of the option
 * @property {ReasonType[]} reasons - The reasons for the option
 */
export interface OptionType {
  id: string;
  label: string;
  reasons?: ReasonType[];
}

/**
 * Interface for the List type
 * @interface
 * @property {ReasonType[]} reasons - The reasons in the list
 * @property {OptionType[]} options - The options in the list
 * @property {MetaType} meta - The metadata of the list
 */
export interface ListType {
  reasons: ReasonType[];
  options: OptionType[];
  meta: MetaType;
}

/**
 * Interface for the Meta type, which is metadata for the list
 * @interface
 * @property {string} key - The key of the saved list (for persistence)
 * @property {string} fileName - The filename of the list
 * @property {number} savedAt - The timestamp when the list was saved
 * @property {number} updatedAt - The timestamp when the list was updated
 * @property {string} color - The color of the list item for the SaveList component
 */
export interface MetaType {
  key?: string;
  fileName?: string;
  savedAt?: number;
  updatedAt?: number;
  color?: string;
  initials?: string;
}

/**
 * Function to get the local state
 * @param {ListType} defaultState - The default state
 * @returns {ListType} The local state
 */
function getLocalState(defaultState: ListType) {
  return defaultState;
}

// Initial state of the list
const initialState: ListType = getLocalState({
  reasons: [],
  options: [],
  meta: {},
});

// Options

/**
 * Slice for the options
 * @type {Slice}
 */
export const optionSlice = createSlice({
  name: "option",
  initialState: initialState.options,
  reducers: {
    // Add an option
    addOption: (state, action) => {
      track("event", "add-option");
      state.push({
        label: action.payload.label,
        id: nanoid(4),
      });
    },
    // Delete an option
    deleteOption: (state, action: PayloadAction<{ id: string }>) => {
      track("event", "delete-option");
      return state.filter(
        (item: { id: string }) => item.id !== action.payload.id,
      );
    },
    // Edit an option
    editOption: (
      state,
      action: PayloadAction<{ id: string; label: string }>,
    ) => {
      track("event", "edit-option");
      return state.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, label: action.payload.label };
        } else {
          return item;
        }
      });
    },
    // Clear all options
    clearOptions: () => {
      track("event", "clear-options");
      return [];
    },
    // Replace the state of the options
    replaceOptionsState: (state, action: PayloadAction<OptionType[]>) => {
      return action.payload;
    },
  },
});

// Export the actions and the reducer of the options
export const {
  addOption,
  deleteOption,
  editOption,
  clearOptions,
  replaceOptionsState,
} = optionSlice.actions;

export const optionsReducer = optionSlice.reducer;

// Reasons

/**
 * Slice for the reasons
 * @type {Slice}
 */
export const reasonSlice = createSlice({
  name: "reason",
  initialState: initialState.reasons,
  reducers: {
    // Add a reason
    addReason: (state, action) => {
      track("event", "add-reason", {
        event_label: action.payload.side,
      });
      state.push({
        label: action.payload.label,
        optionId: action.payload.optionId,
        id: nanoid(4),
        side: action.payload.side,
        weight: WeightEnum.LOW,
      });
    },
    // Delete a reason
    deleteReason: (state, action: PayloadAction<{ id: string }>) => {
      track("event", "delete-reason");
      return state.filter(
        (item: Partial<ReasonType>) => item.id !== action.payload.id,
      );
    },
    // Edit the weight of a reason
    editReasonWeight: (
      state,
      action: PayloadAction<{ id: string; weight: WeightEnum }>,
    ) => {
      track("event", "edit-reason-weight");
      return state.map((item: ReasonType) => {
        if (item.id === action.payload.id) {
          return { ...item, weight: action.payload.weight };
        } else {
          return item;
        }
      });
    },
    // Edit a reason
    editReason: (
      state,
      action: PayloadAction<{ id: string; label: string }>,
    ) => {
      track("event", "edit-reason");
      return state.map((item: ReasonType) => {
        if (item.id === action.payload.id) {
          return { ...item, label: action.payload.label };
        } else {
          return item;
        }
      });
    },
    // Replace the state of the reasons
    replaceReasonsState: (state, action: PayloadAction<ReasonType[]>) => {
      return action.payload;
    },
  },
});

// Export the actions and the reducer of the reasons
export const {
  addReason,
  deleteReason,
  editReason,
  editReasonWeight,
  replaceReasonsState,
} = reasonSlice.actions;

export const reasonsReducer = reasonSlice.reducer;

/**
 * Slice for the metadata
 * @type {Slice}
 */
export const metaSlice = createSlice({
  name: "meta",
  initialState: initialState.meta,
  reducers: {
    // Replace the state of the metadata
    replaceMetaState: (state, action: PayloadAction<Partial<MetaType>>) => {
      return { ...state, ...action.payload };
    },
    // Clear the metadata
    clearMetaState: () => {
      return {};
    },
  },
});

// Export the action and the reducer of the metadata
export const { replaceMetaState, clearMetaState } = metaSlice.actions;

export const metaReducer = metaSlice.reducer;
