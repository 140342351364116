import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { track } from "@/utils/track";
import i18nextConfig from "../next-i18next.config";
import languageDetector from "@/utils/languageDetector";
import { useRouter } from "next/router";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const locales = [
  ["en", "English", "us"],
  ["de", "Deutsch", "de"],
  ["es", "Español", "es"],
  ["fr", "Français", "fr"],
  ["pt", "Português", "pt"],
];

export default function LocaleMenu({ bottom }: { bottom?: boolean }) {
  const router = useRouter();
  const changeLanguage = (lng: string) => {
    let pName = router.pathname;
    track("event", "change-language", {
      event_label: lng,
    });
    if (languageDetector.cache) languageDetector.cache(lng);

    if (lng === i18nextConfig.i18n.defaultLocale && !router.query.contentId) {
      pName = pName.replace(`[locale]`, "");
      router.push(pName);
    } else {
      if (router.query.contentId) {
        router.push({
          pathname: "/[locale]/[contentId]",
          query: { locale: lng, contentId: router.query.contentId },
        });
      } else if (router.asPath.includes("/articles")) {
        router.push({
          pathname: "/[locale]/articles",
          query: { locale: lng },
        });
      } else {
        router.push({
          pathname: "/[locale]",
          query: { locale: lng },
        });
      }
    }
  };

  function getLocale() {
    return locales.find((i) => i[0] === router.query.locale) || locales[0];
  }

  return (
    <Menu
      as="div"
      data-test="locale-menu"
      className="relative inline-block text-left self-center sm:text-right z-10"
    >
      <div>
        <Menu.Button
          className="inline-flex items-center ml-3 px-1 py-1 text-sm leading-4 font-medium bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-8 self-center dark:border-0  dark:bg-transparent dark:text-white"
          data-test={`locale-menu-button-${getLocale()[0]}`}
        >
          <>
            <span
              aria-label={getLocale()[2]}
              role={"img"}
              className={`fi fi-${getLocale()[2]}`}
            ></span>
          </>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${
            bottom
              ? "origin-bottom  left-3 bottom-8"
              : "origin-top-right right-0"
          } absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:border-0  dark:bg-darkPanelBg dark:ring-1 dark:ring-inset dark:ring-white/10 `}
        >
          <div className="py-1">
            {locales.map(([langCode, langName, flag]) => {
              return (
                <Menu.Item key={langCode}>
                  {({ active }) => (
                    <button
                      onClick={() => changeLanguage(langCode)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm w-full text-left dark:bg-darkPanelBg dark:hover:bg-white/20 dark:text-white dark:border-white/10",
                      )}
                    >
                      <span className={`fi fi-${flag} mr-4`}></span>
                      {langName}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
