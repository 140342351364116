import React from "react";

interface PanelProps {
  children: React.ReactNode;
  additionalClasses?: string;
}

export const Panel = ({ children, additionalClasses }: PanelProps) => {
  return (
    <div
      className={`divide-y divide-gray-200 overflow-hidden rounded-lg shadow dark:bg-darkPanelBg m-2 ${additionalClasses}`}
    >
      {children}
    </div>
  );
};
