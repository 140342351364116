import React, { useEffect, useState } from "react";
import { localSavedStatesStore } from "@/state/localSavedStatesStore";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import {
  replaceOptionsState,
  replaceReasonsState,
  replaceMetaState,
  ListType,
  clearMetaState,
} from "@/state/reducers";
import { track } from "@/utils/track";
import { store } from "@/state/store";
import { confirmUnsavedChanges } from "@/utils/compareState";
import { getCollectionStats, getListStats } from "@/state/stateStats";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function getInitialsFromFileName(fileName: string) {
  const words = fileName.split(" ");
  const ignoreWords = ["the", "a", "an", "to", "for", "in", "of", "on"]; // Add any prepositions you want to ignore here
  let initialsArray = words
    .filter((word) => !ignoreWords.includes(word.toLowerCase()))
    .map((word) => word.charAt(0).toUpperCase());
  if (initialsArray.length < 2 && words.length >= 2) {
    initialsArray = words
      .slice(0, 2)
      .map((word) => word.charAt(0).toUpperCase());
  }
  const initials = initialsArray.join("");
  return initials.slice(0, 2);
}

async function retrieveAllItems(): Promise<ListType[]> {
  try {
    const keys = await localSavedStatesStore.keys();
    const itemPromises: Promise<ListType | null>[] = keys.map((key) =>
      localSavedStatesStore.getItem(key),
    );
    const items = await Promise.all(itemPromises);
    const filteredList = items
      .map((item) => {
        if (!item?.meta.fileName) {
          console.error("Failed to retrieve properly formed listItem", item);
          if (item?.meta.key) {
            localSavedStatesStore.removeItem(item.meta.key);
          }
          throw new Error("Failed to retrieve properly formed listItem");
        }
        const initials = getInitialsFromFileName(item.meta.fileName);
        return { ...item, meta: { ...item.meta, initials } };
      })
      .filter((item) => item.meta.fileName);
    track("event", "retrieve-all-items", getCollectionStats(filteredList));
    return filteredList;
  } catch (error) {
    console.error("Failed to retrieve items:", error);
    return [];
  }
}

export function SaveList({ handleClose }: { handleClose: () => void }) {
  const [lists, setLists] = useState<ListType[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  async function handleDeleteClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: ListType,
  ) {
    event.stopPropagation();
    const userConfirmation = window.confirm(
      t("SaveList.AreYouSureYouWantToDeleteThisList"),
    );
    if (userConfirmation) {
      try {
        if (item && item.meta.key) {
          await localSavedStatesStore.removeItem(item.meta.key);
          // Refresh the list after deletion
          const items = await retrieveAllItems();
          setLists(items.filter(Boolean));
          const currentState = store.getState();
          if (currentState.meta.key === item.meta.key) {
            dispatch(replaceOptionsState([]));
            dispatch(clearMetaState());
          }
        }
      } catch (error) {
        console.error("Failed to delete item:", error);
      }
    }
  }

  async function handleEditClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    editingItem: ListType,
  ) {
    event.stopPropagation();
    const newFileName = window.prompt(
      "Enter new file name",
      editingItem.meta.fileName,
    );
    if (newFileName && editingItem.meta.key) {
      try {
        const item: ListType | null | undefined =
          await localSavedStatesStore.getItem(editingItem.meta.key);
        if (item && item.meta.fileName && item.meta.key) {
          item.meta.fileName = newFileName;
          await localSavedStatesStore.setItem(item.meta.key, item);
          // Dispatch the action to update the meta state in Redux
          dispatch(replaceMetaState(item.meta));
          // Refresh the list after editing
          const items = await retrieveAllItems();
          setLists(items.filter(Boolean));
        }
      } catch (error) {
        console.error("Failed to edit item:", error);
      }
    }
  }

  useEffect(() => {
    retrieveAllItems().then((items) => {
      const listsWithGeneratedMetadata = items.filter(Boolean); // filter out undefined values
      setLists(listsWithGeneratedMetadata);
    });
  }, []);

  function handleClickLoad(savedState: ListType) {
    confirmUnsavedChanges(
      store.getState(),
      t(
        "AppControls.YouHaveUnsavedChangesDoYouWantToContinueAndDiscardChanges",
      ),
      "load-list",
    ).then((discardChanges) => {
      if (discardChanges) {
        dispatch(replaceOptionsState(savedState.options));
        dispatch(replaceReasonsState(savedState.reasons));
        dispatch(replaceMetaState(savedState.meta));
        handleClose();
        track("event", "load-list", getListStats(savedState));
      }
    });
  }

  return (
    <div data-test={"load-drawer"}>
      <h2 className="text-sm font-medium text-gray-500 mb-8">
        ⚠ {t("SaveList.ListsAreOnlySavedLocallyInYourBrowser")}
      </h2>
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6">
        {lists.map((list) => (
          <li
            key={list.meta.fileName}
            className="col-span-1 flex rounded-md shadow-sm cursor-pointer"
            onClick={() => handleClickLoad(list)} // add onClick event handler here
          >
            <div
              className={classNames(
                list.meta.color || "",
                "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white",
              )}
            >
              {list.meta.initials}
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <div className="font-medium text-gray-900 hover:text-gray-600">
                  {list.meta.fileName}
                </div>
                <p className="text-gray-500">
                  {list.meta.updatedAt
                    ? `${t("SaveList.LastUpdated")} ${new Intl.DateTimeFormat(
                        t("locale"),
                      ).format(new Date(list.meta.updatedAt))}`
                    : ""}
                </p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <div className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <>
                    <button
                      data-test="delete-saved-list-button"
                      onClick={(event) => handleDeleteClick(event, list)}
                      aria-label={`Delete list`}
                    >
                      <TrashIcon className="h-6 w-6 text-gray-400 dark:text-gray-300" />
                    </button>
                    <button
                      data-test="edit-saved-list-button"
                      onClick={(event) => {
                        handleEditClick(event, list);
                      }}
                      aria-label={`Edit list`}
                    >
                      <PencilSquareIcon className="h-6 w-6 text-gray-400 dark:text-gray-300" />
                    </button>
                  </>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
