import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { metaReducer, optionsReducer, reasonsReducer } from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";
const persistConfig = {
  key: "root",
  storage: localforage,
};

const rootReducer = combineReducers({
  reasons: reasonsReducer,
  options: optionsReducer,
  meta: metaReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store, { manualPersist: true });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
