import { CheckCircleIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { AppDispatch, persistor, store } from "@/state/store";
import { localSavedStatesStore } from "@/state/localSavedStatesStore";
import { FolderOpen, Save } from "lucide-react";
import { LoadDrawer } from "@/components/LoadDrawer";
import React, { useEffect, useState } from "react";
import { clearOptions, ListType, replaceMetaState } from "@/state/reducers";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { useTranslation } from "next-i18next";
import { Panel } from "@/components/Panel";
import { track } from "@/utils/track";
import { generateUUID } from "@/utils/uuid";
import { confirmUnsavedChanges } from "@/utils/compareState";
import { getListStats } from "@/state/stateStats";

const bgColorClasses = [
  "bg-cyan-950",
  "bg-yellow-700",
  "bg-red-900",
  "bg-green-900",
  "bg-indigo-900",
  "bg-purple-950",
  "bg-pink-800",
];

export const AppControls = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fileName = useAppSelector((state) => state.meta.fileName);
  const fileNameInputRef = React.useRef<HTMLInputElement>(null);
  const [editFileNameValue, setEditFileNameValue] = React.useState(fileName);
  useEffect(() => {
    // update editFileNameValue when fileName changes
    setEditFileNameValue(fileName);
  }, [fileName]);
  const [editingFileName, setEditingFileName] = React.useState(false);
  const handleDoubleClick = () => {
    if (!fileName) {
      track("event", "double-click-empty-filename");
    } else {
      track("event", "double-click-filename");
    }
    setEditingFileName(true);
  };

  // confirm unsaved changes on page unload
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      confirmUnsavedChanges(
        store.getState(),
        t(
          "AppControls.YouHaveUnsavedChangesDoYouWantToContinueAndDiscardChanges",
        ),
        "unload-page",
      ).then((shouldDiscard) => {
        if (shouldDiscard) {
          return;
        } else {
          e.preventDefault();
        }
      });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [t]);

  async function saveNewState(fileName: string, key: string, state: ListType) {
    if (fileName.length > 80) {
      alert(t("AppControls.ErrorFilenameCannotBeLongerThan80Characters"));
      return;
    }
    const timestamp = Date.now();

    const color =
      bgColorClasses[Math.floor(Math.random() * bgColorClasses.length)];

    const newState = {
      ...state,
      meta: {
        fileName: fileName,
        savedAt: timestamp,
        updatedAt: timestamp,
        key,
        color,
      },
    };
    await localSavedStatesStore.setItem(key, newState);
    // Dispatch the replaceMetaState action to update the metadata in the redux store
    dispatch(replaceMetaState(newState.meta));
  }

  async function saveState(state: ListType, key: string) {
    // Update existing file
    const newState = {
      ...state,
      meta: {
        ...state.meta,
        updatedAt: Date.now(),
      },
    };
    await localSavedStatesStore.setItem(key, newState);
    // Dispatch the replaceMetaState action to update the metadata in the redux store
    dispatch(replaceMetaState(newState.meta));
  }

  async function promptAndSaveNewState(key: string) {
    const state = store.getState();
    const fileName =
      state.meta.fileName ||
      window.prompt(t("AppControls.PleaseEnterAFileName"));
    if (fileName) {
      await saveNewState(fileName, key, state);
    } else {
      alert(t("AppControls.ErrorFilenameCannotBeEmpty"));
      return;
    }
  }

  async function handleSubmitEditFileName(e: React.SyntheticEvent) {
    e.preventDefault();
    const state = store.getState();

    // Check if editFileNameValue is not an empty string if there's already a filanem set
    if (
      state.meta.fileName &&
      (!editFileNameValue || !editFileNameValue.trim())
    ) {
      alert(t("AppControls.ErrorFilenameCannotBeEmpty"));
      return;
    }

    // Dispatch the replaceMetaState action to update the filename in the redux store
    dispatch(replaceMetaState({ fileName: editFileNameValue }));
    track("event", "edit-filename");

    // Get the current state from the redux store

    // Create a deep copy of the state
    const newState = {
      ...state,
      meta: {
        ...state.meta,
        fileName: editFileNameValue,
      },
    };

    if (newState.meta.key) {
      await localSavedStatesStore.setItem(newState.meta.key, newState);
    }

    setEditingFileName(false);
  }

  function handleChangeEditFileName(e: React.FormEvent<HTMLInputElement>) {
    setEditFileNameValue(e.currentTarget.value);
  }

  async function handleClickSave() {
    await persistor.flush();
    const state = store.getState();
    // Check if there is existing save key
    if (state.meta.key) {
      track("event", "save-list", getListStats(state));
      await saveState(state, state.meta.key);
    } else {
      track("event", "save-new-list", getListStats(state));
      await promptAndSaveNewState(generateUUID());
    }
  }

  function closeEditFileName(name = "") {
    setEditingFileName(false);
    setEditFileNameValue(name);
  }

  const [isLoadDrawerOpen, setIsLoadDrawerOpen] = useState(false);

  function clearAll() {
    return (dispatch: AppDispatch) => {
      dispatch(clearOptions());
      dispatch(
        replaceMetaState({
          fileName: "",
          savedAt: undefined,
          updatedAt: undefined,
          key: undefined,
          color: "",
        }),
      );
    };
  }

  return (
    <div className="flex-1">
      <div className="max-w-3xl mx-auto">
        <Panel additionalClasses={"p-1 px-4"}>
          <nav
            className="flex pace-x-8 lg:py-2 flex-row-reverse"
            aria-label={t("AppControls.AppControls")}
          >
            <>
              {/* App buttons */}
              <button
                type="button"
                data-test="save-button"
                className="mx-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-8 self-center dark:border-0  dark:bg-white/10 dark:hover:bg-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10 dark:text-white"
                onClick={() => {
                  handleClickSave();
                  closeEditFileName();
                }}
              >
                <Save
                  className="ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                  strokeWidth={1.5}
                  data-test={"open-onboarding-modal-button"}
                />
                <span className="hidden sm:block">{t("AppControls.Save")}</span>
              </button>
              <button
                type="button"
                data-test="load-button"
                className="mx-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-8 self-center dark:border-0  dark:bg-white/10 dark:hover:bg-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10 dark:text-white"
                onClick={() => {
                  track("event", "open-load-drawer");
                  setIsLoadDrawerOpen(true);
                  closeEditFileName();
                }}
              >
                <FolderOpen
                  className="ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                  strokeWidth={1.5}
                />
                <span className="hidden sm:block">{t("AppControls.Load")}</span>
              </button>
              <button
                type="button"
                data-test="new-button"
                className="mx-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-8 self-center dark:border-0  dark:bg-white/10 dark:hover:bg-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10 dark:text-white"
                onClick={() => {
                  confirmUnsavedChanges(
                    store.getState(),
                    t(
                      "AppControls.YouHaveUnsavedChangesDoYouWantToContinueAndDiscardChanges",
                    ),
                    "click-new",
                  ).then((shouldDiscard) => {
                    if (shouldDiscard) {
                      dispatch(clearAll());
                      closeEditFileName();
                    }
                  });
                }}
              >
                <DocumentIcon
                  className="ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                  strokeWidth={1.5}
                />
                <span className="hidden sm:block">{t("AppControls.New")}</span>
              </button>
              <LoadDrawer
                isOpen={isLoadDrawerOpen}
                onClose={() => setIsLoadDrawerOpen(false)}
              />
              {editingFileName ? (
                <form
                  className="flex mr-auto "
                  onSubmit={handleSubmitEditFileName}
                >
                  <input
                    ref={fileNameInputRef}
                    type="text"
                    name={`edit-filename`}
                    data-test="edit-option-input"
                    className="flex-1 rounded-tl border-1 px-3 py-0 h-8 text-md !leading-none border-r-0 dark:border-0 dark:text-white dark:bg-white/5 dark:ring-1 dark:ring-inset dark:ring-white/10 w-1/2 border-gray-300 shadow-sm"
                    onChange={handleChangeEditFileName}
                    onKeyUp={(e) => {
                      if (e.key === "Escape") {
                        closeEditFileName(fileName || "");
                      }
                    }}
                    value={editFileNameValue}
                  />
                  <button
                    aria-label={t("AppControls.SaveUpdatedFileName")}
                    type="submit"
                    className="rounded-br border-2 border-gray-200 bg-gray-200 p-2 h-8 dark:border-0  dark:bg-white/10 dark:hover:bg-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10"
                  >
                    <CheckCircleIcon className="h-4 w-4" />
                  </button>
                </form>
              ) : fileName ? (
                <span
                  data-test="filename-heading"
                  onClick={handleDoubleClick}
                  className="mr-auto text-lg sm:text-2xl font-light text-gray-800 dark:text-gray-400 w-1/2"
                >
                  {fileName}
                </span>
              ) : (
                <span
                  onDoubleClick={handleDoubleClick}
                  className="mr-auto text-2xl font-light text-gray-800 dark:text-gray-400 w-1/2 hover:cursor-text"
                ></span>
              )}
            </>
          </nav>
        </Panel>
      </div>
    </div>
  );
};
