import { getCookieConsentValue } from "react-cookie-consent";
export const COOKIE_NAME = "pcl-allow-cookies";

export function getCookieConsent(): boolean | undefined {
  const cookieConsentValue = getCookieConsentValue(COOKIE_NAME);
  if (cookieConsentValue === "true") {
    return true;
  } else if (cookieConsentValue === "false") {
    return false;
  } else {
    return undefined;
  }
}

export function deleteCookies(cookieNames: string[]) {
  for (let i = 0; i < cookieNames.length; i++) {
    document.cookie =
      cookieNames[i] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function removeScript(id: string) {
  const scriptElement: HTMLElement | null = document.getElementById(id);
  if (scriptElement) {
    scriptElement.parentNode?.removeChild(scriptElement);
  }
}
