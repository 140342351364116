import { ListType, SideEnum, WeightEnum } from "./reducers";

export function getListStats(state: ListType) {
  const countOptions = state.options.length;
  const countReasons = state.reasons.length;
  const countProReasons = state.reasons.filter(
    (reason) => reason.side === SideEnum.PRO,
  ).length;
  const countConReasons = state.reasons.filter(
    (reason) => reason.side === SideEnum.CON,
  ).length;

  // Calculate the balance for each option
  const balances = state.options.map((option) => {
    const optionReasons = state.reasons.filter(
      (reason) => reason.optionId === option.id,
    );
    const optionProReasons = optionReasons.filter(
      (reason) => reason.side === SideEnum.PRO,
    ).length;
    const optionConReasons = optionReasons.filter(
      (reason) => reason.side === SideEnum.CON,
    ).length;
    return (optionProReasons / (optionProReasons + optionConReasons)) * 100;
  });

  // Calculate the median balance
  balances.sort((a, b) => a - b);
  const medianBalanceScore =
    balances.length % 2 === 0
      ? (balances[balances.length / 2 - 1] + balances[balances.length / 2]) / 2
      : balances[Math.floor(balances.length / 2)];

  // Calculate the count of options with mostly pro and con reasons
  const countMostlyProOptions = balances.filter(
    (balance) => balance > 50,
  ).length;
  const countMostlyConOptions = balances.filter(
    (balance) => balance < 50,
  ).length;

  // Calculate the number of reasons for each option
  const reasonsPerOption = state.options.map((option) => {
    const optionReasons = state.reasons.filter(
      (reason) => reason.optionId === option.id,
    );
    return optionReasons.length;
  });

  // Calculate the median number of reasons per option
  reasonsPerOption.sort((a, b) => a - b);
  const medianReasonCount =
    reasonsPerOption.length % 2 === 0
      ? (reasonsPerOption[reasonsPerOption.length / 2 - 1] +
          reasonsPerOption[reasonsPerOption.length / 2]) /
        2
      : reasonsPerOption[Math.floor(reasonsPerOption.length / 2)];

  // Count the number of reasons with a HIGH weight
  const countOfWeightedReasons = state.reasons.filter(
    (reason) => reason.weight === WeightEnum.HIGH,
  ).length;

  return {
    "epn.list_count_options": countOptions,
    "epn.list_count_reasons": countReasons,
    "epn.list_count_pro_reasons": countProReasons,
    "epn.list_count_con_reasons": countConReasons,
    "epn.list_median_balance_score": medianBalanceScore,
    "epn.list_count_mostly_pro_options": countMostlyProOptions,
    "epn.list_count_mostly_con_options": countMostlyConOptions,
    "epn.list_median_reason_count": medianReasonCount,
    "epn.list_count_of_weighted_reasons": countOfWeightedReasons,
  };
}

export function getCollectionStats(lists: ListType[]) {
  const countLists = lists.length;

  // Calculate the median number of options per list
  const optionsPerList = lists.map((list) => list.options.length);
  optionsPerList.sort((a, b) => a - b);
  const medianOptionsPerList =
    optionsPerList.length % 2 === 0
      ? (optionsPerList[optionsPerList.length / 2 - 1] +
          optionsPerList[optionsPerList.length / 2]) /
        2
      : optionsPerList[Math.floor(optionsPerList.length / 2)];

  return {
    "epn.collection_count_lists": countLists,
    "epn.collection_median_options_per_list": medianOptionsPerList,
  };
}
