/* eslint-disable @typescript-eslint/no-explicit-any */

import { isDataProtective } from "./dataProtectiveCheck";
import { deleteCookies, removeScript, getCookieConsent } from "./cookieConsent";

declare global {
  interface Window {
    dataLayer: never;
  }
}

export const GA_SCRIPT_ID = "gaScript";
const GA_TRACKING_ID = "G-3G2KQMVJR0";
const noop = function () {};

export function initTracking() {
  if (getCookieConsent() !== false) {
    loadMinimalGAScript().catch((e) => console.error(e));
  }

  if (!isDataProtective() || getCookieConsent()) {
    acceptTracking();
  } else if (isDataProtective() && getCookieConsent() === false) {
    denyTracking();
    deleteCookies(["_ga", "_gid", "_gat"]);
    delete window.dataLayer;
    delete window.minimalAnalytics;
  }
}

const _track = function (
  ...args: [string, string, Record<string, string | number>?]
) {
  if (typeof window !== "undefined") {
    // @ts-expect-error window.__DEBUG_TRACK__
    if (window.__DEBUG_TRACK__ === true) {
      console.log("track", args);
    }
    if (args[0] === "event") {
      const extendedEventData = {
        ...args[2],
      };
      if (window.track) {
        window.track(GA_TRACKING_ID, {
          type: args[1],
          event: extendedEventData,
        });
      }
    }
  }
};

export const track =
  isDataProtective() !== true || getCookieConsent() !== false ? _track : noop;

export async function loadMinimalGAScript() {
  window.minimalAnalytics = {
    trackingId: GA_TRACKING_ID,
    defineGlobal: true,
  };
  return import("@minimal-analytics/ga4").then(() => {
    if (window.track) {
      window.track(GA_TRACKING_ID);
      window.track(GA_TRACKING_ID, {
        type: "page_view",
        event: {
          "ep.prefers_color_scheme": window.matchMedia(
            "(prefers-color-scheme: dark)",
          ).matches
            ? "dark"
            : window.matchMedia("(prefers-color-scheme: light)").matches
              ? "light"
              : "none",
        },
      });
    }
  });
}

export function acceptTracking() {
  if (!document.getElementById(GA_SCRIPT_ID)) {
    loadMinimalGAScript().catch((e) => console.error(e));
  }
}

export function denyTracking() {
  removeScript(GA_SCRIPT_ID);
}
