import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/file";
import { useRouter } from "next/router";
import i18nextConfig from "@/next-i18next.config";
import { useTheme } from "next-themes";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const OnboardingAnimation = ({
                                      className = "",
                                    }: {
  className: string;
}) => {
  const { resolvedTheme } = useTheme();
  const [isClient, setIsClient] = useState(false);
  const router = useRouter();

  const currentLocale = i18nextConfig.i18n.locales.includes(
    router.query.locale as string,
  )
    ? router.query.locale
    : i18nextConfig.i18n.defaultLocale;

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isClient && (
        <div
          style={{ paddingTop: "56.25%" }}
          className={classNames("player-wrapper relative max-w-md", className)}
        >
          <ReactPlayer
            className="react-player absolute top-0 left-0"
            width="100%"
            height="100%"
            url={`/tutorials/pros-cons-list-${resolvedTheme}-${currentLocale}.mp4`}
            loop={true}
            muted={true}
            playing={true}
            playsinline={true}
          />
        </div>
      )}
    </>
  );
};

export default OnboardingAnimation;