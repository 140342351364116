import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import languageDetector from "../utils/languageDetector";
import { useRouter } from "next/router";
import { config } from "@/site.config.js";
import { appWithTranslation, useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { store } from "@/state/store";
import LocaleMenu, { locales } from "@/components/LocaleMenu";
import { CookieConsent } from "react-cookie-consent";
import { isDataProtective } from "@/utils/dataProtectiveCheck";
import TopHeader from "@/components/TopHeader";
import { acceptTracking, denyTracking } from "@/utils/track";
import { COOKIE_NAME } from "@/utils/cookieConsent";
import Head from "next/head";
import * as Sentry from "@sentry/react";
import { initTracking } from "@/utils/track";
import Link from "next/link";
import i18nextConfig from "@/next-i18next.config";
import { AppControls } from "@/components/AppControls";
import { getSplitTestVariant } from "@/utils/splitTest";
import { ThemeProvider } from 'next-themes'


function App({ Component, pageProps }: AppProps) {
  const [isClient, setIsClient] = useState(false);

  const router = useRouter();
  const isAppPath =
    router.pathname === "/" ||
    router.pathname === "/[locale]" ||
    router.pathname === "/[locale]?*" ||
    router.pathname === "/?*";
  const { t } = useTranslation();
  const termsHref = `/${
    router.query.locale &&
    router.query.locale !== i18nextConfig.i18n.defaultLocale
      ? router.query.locale
      : i18nextConfig.i18n.defaultLocale
  }/terms-of-service`;
  useEffect(() => {
    setIsClient(true);
    const environment = window.location.href.startsWith(config.prodDomain)
      ? "production"
      : "development";
    if (environment === "production") {
      initTracking();
    }
    Sentry.init({
      dsn: "https://89863ee5d6a640418e61b806e25797aa@o78809.ingest.us.sentry.io/6431694",
      integrations: [Sentry.browserTracingIntegration({enableInp: true,})],
      environment,
      tracesSampleRate: 1.0,
    });
    Sentry.setTag("Branch", getSplitTestVariant());
  }, []);

  // Get the current path segments
  const pathSegments = router.asPath.split("/");

  // Remove the first segment if it's a locale
  if (locales.map((locale) => locale[0]).includes(pathSegments[1])) {
    pathSegments.splice(1, 1);
  }

  // Join the segments back together to get the path without the locale
  const pathWithoutLocale = pathSegments.join("/").replace(/\/$/, "");

  return (
    <React.StrictMode>
      <ThemeProvider attribute="class">
      <Provider store={store}>
        <div className="App flex flex-col min-h-screen dark:bg-darkBodyBg dark:text-white">
          <Head>
            <meta charSet="utf-8" />
            <link rel="icon" href="/favicon.ico" sizes="any" />
            <meta name="keywords" content={t("keywords")} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            {locales.map((locale) => {
              let href = `${config.prodDomain}/${locale[0]}${pathWithoutLocale}`;
              // pathWithoutLocale is empty AND locale is the default EN, then we don't need to add the locale to the href
              if (
                pathWithoutLocale === "" &&
                locale[0] === i18nextConfig.i18n.defaultLocale
              ) {
                href = `${config.prodDomain}`;
              }
              return (
                <link
                  key={locale[0]}
                  rel="alternate"
                  href={href}
                  hrefLang={locale[0]}
                />
              );
            })}
          </Head>
          <header role="banner">
            <TopHeader />
          </header>
          <main role="main">
            {isAppPath && <AppControls />}
            <Component {...pageProps} locale={languageDetector.detect()} />
          </main>

          <footer
            className="sticky top-[100vh] flex-none py-6 px-2 self-center text-center md:text-left md:self-start text-xs md:w-full"
            role="contentinfo"
          >
            {isClient && isDataProtective() !== false && (
              <CookieConsent
                enableDeclineButton
                onDecline={denyTracking}
                cookieName={COOKIE_NAME}
                buttonText={t("CookieConsent.accept")}
                declineButtonText={t("CookieConsent.decline")}
                ariaAcceptLabel={t("CookieConsent.accept")}
                ariaDeclineLabel={t("CookieConsent.decline")}
                declineButtonStyle={{ backgroundColor: "#C11535" }}
                style={{ fontSize: ".8em" }}
                containerClasses={"test-cookie-consent"}
                buttonStyle={{ backgroundColor: "#3ab56b" }}
                onAccept={acceptTracking}
              >
                {t("CookieConsent.message")}
              </CookieConsent>
            )}
            <div className="inline-block mx-2">
              <LocaleMenu bottom={true} />
            </div>
            <span className={"sm:after:content-['|'] block sm:inline"}>
              &copy; {new Date().getFullYear()}{" "}
            </span>{" "}
            <Link href={termsHref}>{t("Footer.termsLinkTitle")}</Link>
          </footer>
        </div>
      </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default appWithTranslation(App);
